<template>
  <div>
    <!-- <v-row align="stretch">
      <v-col cols="12" sm="6" lg="4">
        <v-card flat class="pa-4 text-center" height="100%">
          <v-avatar v-if="g_user.user.avatar" width="150px" height="150px"
            ><v-img src="g_user.user.avatar"></v-img>
          </v-avatar>
          <v-avatar v-else width="150px" height="150px" color="primary"
            ><v-icon dark size="100"> mdi-account-circle </v-icon>
          </v-avatar>
          <div class="title my-4 text-left">
            Hi, {{ g_user.person.name }}
            <div class="caption--text">Apa yang kamu mulai?</div>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn
              color="primary"
              depressed
              :style="
                $vuetify.breakpoint.mdAndDown ? 'width: 130px' : 'width: 150px'
              "
              @click="$router.push({ name: 'ElearningForm' })"
            >
              E-Learning
            </v-btn>
            <v-btn
              color="secondary"
              depressed
              :style="
                $vuetify.breakpoint.mdAndDown ? 'width: 130px' : 'width: 150px'
              "
              @click="$router.push({ name: 'CreateE-Test' })"
            >
              E-Test
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <v-card flat class="pa-4 mb-4">
          <v-list three-line>
            <v-list-item>
              <v-list-item-avatar color="rgba(72, 134, 255, 0.16)" size="65">
                <v-icon color="#4886FF" size="30">mdi-book-multiple</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="caption--text">
                  E-Learning Disimpan
                </v-list-item-title>
                <v-list-item-subtitle class="title--text">
                  {{ data.myElearning }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card flat class="pa-4">
          <v-list three-line>
            <v-list-item>
              <v-list-item-avatar color="rgba(227, 177, 5, 0.16)" size="65">
                <v-icon color="#E3B105" size="30"> mdi-clipboard-text </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="caption--text">
                  E-Test Dikerjakan
                </v-list-item-title>
                <v-list-item-subtitle class="title--text">
                  {{ data.myEtest }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" lg="4">
        <v-card flat class="pa-4">
          <div v-if="!loadingDiagram">
            <VueApexChart
              type="bar"
              height="260"
              :options="chartOptions"
              :series="chartOptions.series"
            />
          </div>
          <div v-else>Loading ...</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div class="title font-weight-black mb-2">E-Test Saya</div>
        <v-card flat class="pa-4">
          <v-data-table
            :headers="headers"
            :items="etestData"
            :loading="loadingEtest"
            :options.sync="optionsEtest"
            :server-items-length="totalEtest"
            class="elevation-0"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div class="title font-weight-black mb-2">E-Learning Saya</div>
        <v-card flat class="pa-4">
          <v-data-table
            :headers="headers"
            :items="elearningData"
            :loading="loadingElearning"
            :options.sync="optionsElearning"
            :server-items-length="totalElearning"
            class="elevation-0"
          />
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getEtestList } from "@/api/etest";
import { getElearning } from "@/api/elearning";
// import {
//   getMyElearning,
//   getMyEtest,
//   getHistoryEtest,
//   getEtestDiagram,
//   getLastEtest,
//   getLastElearning,
// } from "../../api/admin/dashboard";

export default {
  name: "DashboardAdmin",
  data() {
    return {
      headers: [
        { text: "Judul", value: "title" },
        { text: "Mata Pelajaran", value: "subject" },
      ],
      queryEtest: {
        limit: 10,
        page: 1,
      },
      queryElearning: {
        limit: 10,
        page: 1,
      },
      etestData: [],
      elearningData: [],
      optionsEtest: {},
      optionsElearning: {},
      totalEtest: 0,
      totalElearning: 0,
      loadingEtest: false,
      loadingElearning: false,
      data: {
        myElearning: 0,
        myEtest: 0,
        etestDiagram: [],
        etestHistory: [],
        lastEtest: [],
        lastElearning: [],
      },
      etestHistoryHeaders: [
        { text: "Judul", value: "title" },
        { text: "Mata Pelajaran", value: "subject" },
        { text: "Nilai", value: "score" },
        { text: "Poin", value: "point" },
      ],
      loadingDiagram: false,
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#E3B105", "#07294D"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          colors: ["#E3B105", "#07294D"],
          opacity: 1,
        },
        xaxis: {
          type: "category",
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].tooltip[dataPointIndex];

            return data.type === "week"
              ? "<div class='pa-2'> Nilai Terendah : <br>" +
                  `<b>${data.name}</b> (${data.score})` +
                  "</div>"
              : "<div class='pa-2'> Nilai Tertinggi : <br>" +
                  `<b>${data.name}</b> (${data.score})` +
                  "</div>";
          },
        },
        series: [
          {
            name: "Nilai Terendah",
            data: [],
            tooltip: [],
          },
          {
            name: "Nilai Tertinggi",
            data: [],
            tooltip: [],
          },
        ],
      },
    };
  },
  watch: {
    optionsEtest: {
      handler(param) {
        this.queryEtest.page = param.page;
        this.queryEtest.limit = param.itemsPerPage;

        this.getDataEtest();
      },
      deep: true,
    },
    optionsElearning: {
      handler(param) {
        this.queryElearning.page = param.page;
        this.queryElearning.limit = param.itemsPerPage;

        this.getDataElearning();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["g_user"]),
  },
  // created() {
  //   this.loadingDiagram = true;
  //   this.getDataEtest();
  //   this.getDataElearning();
  // },
  // async mounted() {
  //   // this.$store.commit("SET_GLOBAL_LOADING", true);
  //   await getMyElearning().then((res) => {
  //     this.data.myElearning = res.data.data;
  //   });
  //   await getMyEtest().then((res) => {
  //     this.data.myEtest = res.data.data;
  //   });
  //   await getHistoryEtest().then((res) => {
  //     this.data.etestHistory = res.data.data;
  //   });
  //   await getEtestDiagram().then((res) => {
  //     this.chartOptions.xaxis.categories = [];
  //     this.data.etestDiagram = res.data.data;
  //     const d = this.data.etestDiagram;

  //     d.forEach((e) => {
  //       this.chartOptions.xaxis.categories.push(e.subject);
  //       this.chartOptions.series[0].data.push(e.weak_score);
  //       this.chartOptions.series[1].data.push(e.best_score);

  //       this.chartOptions.series[0].tooltip.push({
  //         score: e.weak_score,
  //         name: e.weak_name,
  //         type: "week",
  //       });
  //       this.chartOptions.series[1].tooltip.push({
  //         score: e.best_score,
  //         name: e.best_name,
  //         type: "best",
  //       });
  //     });

  //     this.loadingDiagram = false;
  //   });
  //   await getLastEtest().then((res) => {
  //     this.data.lastEtest = res.data.data;
  //   });
  //   await getLastElearning().then((res) => {
  //     this.data.lastElearning = res.data.data;
  //   });
  //   // this.$store.commit("SET_GLOBAL_LOADING", false);
  // },
  methods: {
    getDataEtest() {
      this.loadingEtest = true;
      getEtestList(this.queryEtest)
        .then((res) => {
          const results = res.data;
          if (results.code) {
            this.etestData = results.data.data;
            this.totalEtest = results.data.total;
          }
        })
        .finally(() => (this.loadingEtest = false));
    },
    getDataElearning() {
      this.loadingElearning = true;
      getElearning(this.queryElearning)
        .then((res) => {
          if (res.data.code) {
            this.elearningData = res.data.data.data;
            this.totalElearning = res.data.data.total;
          }
        })
        .finally(() => (this.loadingElearning = false));
    },
  },
};
</script>
