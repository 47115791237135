<template>
  <div id="dashboard">
    <component :is="currentRole" />
  </div>
</template>

<script>
import DashboardAdmin from "./DashboardAdmin.vue";
import DashboardStudent from "./DashboardStudent.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardAdmin,
    DashboardStudent,
  },
  data() {
    return {
      currentRole: "DashboardAdmin",
    };
  },
  computed: {
    role() {
      return this.$store.getters.g_roles;
    },
  },
  async mounted() {
    if (this.role === "ADMIN" || this.role === "TEACHER") {
      this.currentRole = "DashboardAdmin";
    } else {
      this.currentRole = "DashboardStudent";
    }
  },
};
</script>

<style lang="scss">
#dashboard {
  .title--text {
    color: #000000;
    font-size: 2rem;
    font-weight: 600;
  }
  .caption--text {
    color: #a2a2a2;
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
